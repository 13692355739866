<template>
  <div>
    <div class="title-box">
      <van-icon name="arrow-left" class="lefticon" @click="goBack()" />
      <p>检测证书</p>
    </div>
    <div>
      <img :src="url" style="width:100%;height:11rem;" />
      <div style="padding: 0 0 1rem 0;background:#fff;text-align:center;padding-top:0.3rem;">长按保存图片</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      url: ""
    };
  },
  created() {
    this.$http
      .getQrCode({
        title: this.$route.query.title,
        writer: this.$route.query.writer,
        bbname: this.$route.query.bbname,
        tjtime: this.$route.query.tjtime,
        rate: this.$route.query.newrate
      })
      .then(res => {
        console.log(res, 321);
        this.url = res.img_url.encoded;
      });
  },
  methods: {
    goBack() {
      this.$router.back();
    }
  }
};
</script>

<style scoped>
.title-box {
  display: flex;
  background: #228ef7;
}
.title-box p {
  height: 0.8rem;
  text-align: center;
  line-height: 0.8rem;
  color: #fff;
  flex: 0.85;
}
.lefticon {
  line-height: 0.8rem;
  padding: 0 0.3rem;
  color: #fff;
  font-size: 0.4rem;
}
</style>